import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import PageHeader from "../components/common/PageHeader";
import Seo from "../components/default/seo";

export default function Template({ data }) {
  const {
    markdownRemark: { frontmatter, html },
  } = data;
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <PageHeader title={frontmatter.title} />
      <div>
        <div
          className="prose m-12 max-w-5xl prose-h3:text-primaryBlue prose-h3:shadow-none lg:my-24 lg:mx-auto lg:py-8"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
